<template>
  <n-layout style="height: 100vh">
    <n-dialog-provider>
      <slot></slot>
    </n-dialog-provider>
  </n-layout>
</template>

<script>
export default {};
</script>

<style>
</style>