<template>
  <n-form ref="form" @submit.prevent="submit" :rules="rules">
    <slot></slot>
    <n-space justify="end">
      <slot name="footer"></slot>
    </n-space>
  </n-form>
</template>
<script>
export default {
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isValid: false,
    };
  },
  methods: {
    reset() {},
    validate() {
      const vm = this;
      return new Promise((resolve) => {
        vm.$refs.form.value?.validate((errors) => {
          if (!errors) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },
    submit() {
      const vm = this;
      vm.$refs.form.validate((errors) => {
        if (!errors) {
          vm.$emit("onSubmit");
        } else {
          vm.$emit("notValid");
        }
      });
    },
  },
};
</script>

<style></style>
