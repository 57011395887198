<template>
  <n-form-item
    :label="label"
    :path="path"
    class="flex-1"
    :rule="rule"
    :label-style="labelStyle"
  >
    <template v-slot:label>
      <span class="mr-1">
        {{ label }}
      </span>
      <help-text v-if="help"> {{ help }}</help-text>
    </template>
    <n-select
      v-bind="$attrs"
      v-model:value="input"
      :options="options"
      :clearable="clearable"
      :filterable="filterable"
      :placeholder="placeholder"
      :multiple="multiple"
      @update:value="$emit('input:value', input)"
      :get-show="() => true"
      @keydown.enter.prevent
    >
    </n-select>
    <n-button class="select-unit" v-if="unit" type="tertiary">
      {{ unit }}</n-button
    >
  </n-form-item>
</template>

<script>
import { getSettings } from "../../../assets/js/common.helper";
import HelpText from "../HelpText.vue";

export default {
  components: { HelpText },
  inheritAttrs: false,
  props: {
    value: {},
    help: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    clearable: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    filterable: { type: Boolean, default: false },
    path: {},
    options: {
      type: Array,
      default: () => [],
    },
    customRules: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    field: {
      type: Object,
      default: () => ({}),
    },
    label: { type: String, default: "" },
  },
  data() {
    return {
      input: "",
    };
  },
  computed: {
    unit() {
      let settings = getSettings({ ...this.field });
      console.log("field", settings.unit);
      return settings.unit;
    },

    rule() {
      let { rules } = this;
      const vm = this;
      let settings = this.settings || {};
      return {
        trigger: ["submit", "blur", "input"],
        required: settings.is_required || false,
        validator() {
          for (let rule of rules) {
            let msg = rule({ v: vm.input, ...vm.settings });
            if (msg) return new Error(msg);
          }
        },
      };
    },
  },
  watch: {
    input(val) {
      this.input = val;
    },
    value(val) {
      this.input = val;
    },
  },
  mounted() {
    this.input = this.value;
    console.log("options", this.options);
  },
};
</script>

<style>
</style>