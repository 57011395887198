<template>
  <div class="p-3 pt-2 dashed-border mb-3">
    <div class="d-flex items-center dashed-border--bottom mb-2 pb-2">
      <div class="flex-1 p-2" :style="sectionStyle">
        {{ field.Form_Label }}
      </div>
      <fb-field-config :field="field"></fb-field-config>
    </div>
    <draggable
      v-model="fieldList"
      item-key="Field_Id"
      tag="div"
      @change="onPositionChange"
      :style="gridStyle"
      class="form-grid"
    >
      <template #item="{ element }">
        <div class="form-grid__col" style="width: 100%">
          <fb-preview :field="element"></fb-preview>
        </div>
      </template>
    </draggable>
    <fb-add-field :parent="parent"></fb-add-field>
  </div>
</template>

<script>
import formMixins from "../../mixins/form.mixins";
import FbFieldConfig from "./FbFieldConfig.vue";
import draggable from "vuedraggable";
import { FormField } from "../../assets/model/form.master.model";
import FbAddField from "./FbAddField.vue";
import { getSettings } from "../../assets/js/common.helper";
export default {
  name: "FormSection",
  props: {
    field: {},
  },
  mixins: [formMixins],
  components: {
    FbFieldConfig,
    draggable,
    FbAddField,
  },
  data() {
    return {
      fieldList: [],
      title: "",
    };
  },
  watch: {
    formStepFields() {
      this.initFields();
    },
  },
  computed: {
    parent() {
      return new FormField(this.field).Field_Id;
    },
    column() {
      let settings = getSettings({ ...this.field });
      return settings.column || 2;
    },
    gridStyle() {
      let column = this.column;
      let columns = [];
      let i = 0;
      let space = 20 * (column - 1);
      while (i < column) {
        columns.push(` calc((100% - ${space}px)/${column})`);
        i++;
      }
      columns = columns.toString().split(",").join(" ");
      return { "grid-template-columns": `${columns}!important` };
    },
  },
  methods: {
    initFields() {
      this.fieldList = [...this.formStepFields]
        .filter((i) => i.Parent == this.parent)
        .map((i) => ({ ...i }));
    },
    async changeTitle() {},
  },
  mounted() {
    console.log("Grid");
    this.initFields();
  },
};
</script>
<style></style>
