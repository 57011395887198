<template>
  <label v-if="label">
    {{ label }}
  </label>
  <img
    :src="image"
    @error="onError"
    :style="imageStyle"
    ref="image"
    @click="$refs.file.click()"
  />
  <input
    type="file"
    accept="image/*"
    ref="file"
    class="d-none"
    @change="onImageChange"
  />
</template>

<script>
import UploadImage from "../../assets/images/image-upload.png";
import {
  ImagePrefix,
  getSettings,
  getBase64,
} from "../../assets/js/common.helper";
export default {
  props: {
    field: {},
    value: {
      type: [String, File],
      default: () => "",
    },
  },
  emits: ["input", "input:value"],
  data() {
    return {
      image: UploadImage,
    };
  },
  computed: {
    label() {
      return this.field.Form_Label;
    },
    src() {
      const vm = this;
      let image = getSettings({ ...vm.field }).image;
      return ImagePrefix + image;
    },
  },
  methods: {
    onError(e) {
      e.target.src = UploadImage;
    },
    async onImageChange(e) {
      if (e.target.files[0]) {
        let base64 = await getBase64(e.target.files[0]);
        this.image = base64;
        this.$emit("input:value", e.target.files[0]);
      }
      this.$refs.file.value = null;
    },
  },
  beforeMount() {
    if (this.value) {
      this.image = `${ImagePrefix}/${this.value}`;
    }
  },
};
</script>

<style></style>
