<template>
  <n-steps
    v-model:current="currentStep"
    @update:current="onUpdate"
    class="mt-5"
  >
    <n-step v-for="(step, i) in formSteps" :key="i">
      <template #title>
        <div class="ml-1">
          {{ step.Step_name }}
        </div>
        <div class="d-flex">
          <fb-add-step :step="step"></fb-add-step>
          <delete-icon-button
            @click="deleteStep(step)"
            size="small"
          ></delete-icon-button>
        </div>
      </template>
    </n-step>
  </n-steps>
</template>

<script>
import formMixins from "../../mixins/form.mixins";
import DeleteIconButton from "../ui/buttons/DeleteIconButton.vue";
import FbAddStep from "./FbAddStep.vue";
import { FormMaster, FormStep } from "../../assets/model/form.master.model";
import formBuilderService from "../../assets/services/form.builder.service";
export default {
  components: { DeleteIconButton, FbAddStep },
  data() {
    return {
      currentStep: 1,
      dialog: true,
    };
  },
  mixins: [formMixins],
  methods: {
    onUpdate() {
      const vm = this;
      let index = vm.currentStep - 1;
      vm.$store.commit("setFormStep", vm.formSteps[index]);
    },
    deleteStep(item) {
      const vm = this;
      vm.$confirm({
        message: "Are you sure, Do you want to remove this step?",
        onConfirm: async () => {
          try {
            vm.$loader.show();
            let formId = new FormMaster(vm.formData).Formid;
            let step = new FormStep({
              ...item,
              Dml_Indicator: "D",
              Form_id: formId,
            });
            await formBuilderService.formStep(step);
            await vm.fetchFormSteps(formId);
            vm.$loader.hide();
          } catch (error) {
            vm.handleError(error);
          }
        },
        onClose: () => {
          console.log("Close");
        },
      });
    },
  },
};
</script>

<style>
</style>