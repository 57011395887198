<template>
  <n-form-item :path="path" :label-style="labelStyle" :label="label">
    <template v-slot:label>
      <span class="mr-1">
        {{ label }}
      </span>
      <help-text v-if="help"> {{ help }}</help-text>
    </template>
    <n-dynamic-tags
      v-bind="$attrs"
      v-model:value="input"
      clearable
      @keydown.enter.prevent
    >
      {{ label }}
    </n-dynamic-tags>
  </n-form-item>
</template>

<script>
import HelpText from "../HelpText.vue";

export default {
  components: { HelpText },
  inheritAttrs: false,
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    help: { type: String, default: "" },
    path: {},
    customRules: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: String,
      default: "",
    },
    label: { type: String, default: "" },
  },
  data() {
    return {
      input: [],
    };
  },
  computed: {
    rule() {
      let { rules } = this;
      const vm = this;
      let settings = this.settings || {};
      return {
        trigger: ["submit", "blur", "input"],
        required: settings.is_required || false,
        validator() {
          for (let rule of rules) {
            let msg = rule({ v: vm.input, ...vm.settings });
            if (msg) return new Error(msg);
          }
        },
      };
    },
  },
  mounted() {
    this.input = this.value instanceof Array ? this.value : [];
  },
};
</script>

<style>
</style>