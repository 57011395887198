<template>
  <div>
    <span :style="labelStyle">
      <b>
        {{ label }}
      </b>
    </span>
    :
    {{ result }}
  </div>
</template>
  
  <script>
import formMixins from "../../mixins/form.mixins";
import { getSettings } from "../../assets/js/common.helper";
import { Parser } from "hot-formula-parser";
import $ from "jquery";
export default {
  props: {
    label: { type: String, default: "" },
    field: {},
  },
  data() {
    return {};
  },
  mixins: [formMixins],
  computed: {
    result() {
      let { formula } = getSettings({ ...this.field });
      formula = this.formatFormula(formula);
      console.log(formula);
      let val = new Parser().parse(formula);
      return val.result || 0;
    },
  },
  methods: {
    formatFormula(message) {
      let doc = document.createElement("div");
      doc.innerHTML = message;
      $(doc)
        .find("label")
        .each((i, item) => {
          let id = $(item).attr("data-id");
          let val = this.form[id];
          val = parseFloat(val);
          if (isNaN(val)) val = 0;
          $(item).html(val);
        });
      message = doc.innerText;
      message = message.split("\n").join("<br/>");
      message = message.split("x").join("*");
      return message;
    },
  },
};
</script>
