<template>
  <div class="mb-3">
    <f-preview
      v-for="field in fieldList"
      :parentPath="getParentPath"
      v-bind:key="field.Field_Id"
      :field="field"
    />
  </div>
</template>

<script>
import formMixins from "../../mixins/form.mixins";
import { FormField } from "../../assets/model/form.master.model";
import { getSettings } from "../../assets/js/common.helper";
export default {
  name: "FormSection",
  props: {
    field: {},
    parentPath: {},
  },
  mixins: [formMixins],
  data() {
    return {
      title: "",
    };
  },
  computed: {
    column() {
      let settings = getSettings({ ...this.field });
      return settings.column || 2;
    },
    gridStyle() {
      let column = this.column;
      let columns = [];
      let i = 0;
      let space = 20 * (column - 1);
      while (i < column) {
        columns.push(` calc((100% - ${space}px)/${column})`);
        i++;
      }
      columns = columns.toString().split(",").join(" ");
      return { "grid-template-columns": `${columns}!important` };
    },
    parent() {
      return new FormField(this.field).Field_Id;
    },
    fieldList() {
      return [...this.formStepFields]
        .filter((i) => i.Parent == this.parent)
        .map((i) => ({ ...i }));
    },
  },
};
</script>
