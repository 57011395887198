<template>
  <div>
    <icon-button size="small" @click="dialog = true" v-if="type == 'icon'">
      <CallSplitFilled></CallSplitFilled>
    </icon-button>
    <outlined-button v-else @click="dialog = true" :icon="conditionIcon"
      >Conditions</outlined-button
    >

    <n-modal
      v-model:show="dialog"
      preset="card"
      :style="bodyStyle"
      :bordered="false"
      :closeOnEsc="false"
      :maskClosable="false"
      :closable="false"
    >
      <template v-slot:header>
        <div class="d-flex">
          Conditions
          <div class="flex-1"></div>
          <fb-add-condition></fb-add-condition>
        </div>
      </template>
      <n-data-table :columns="headers" :data="list" />
      <div class="d-flex mt-3">
        <div class="flex-1"></div>
        <n-button color="red" quaternary @click="dialog = false"
          >Close</n-button
        >
      </div>
    </n-modal>
  </div>
</template>

<script>
import { h } from "vue";
import { CallSplitFilled } from "@vicons/material";
import OutlinedButton from "../ui/buttons/OutlinedButton.vue";
import IconButton from "../ui/buttons/IconButton.vue";
import DeleteIconButton from "../ui/buttons/DeleteIconButton.vue";
import FbAddCondition from "./FbAddCondition.vue";
import { FormCondition, FormField } from "../../assets/model/form.master.model";
import formBuilderService from "../../assets/services/form.builder.service";
import formMixins from "../../mixins/form.mixins";
// import { NTag } from "naive-ui";

export default {
  components: {
    OutlinedButton,
    CallSplitFilled,
    IconButton,
    FbAddCondition,
  },
  props: {
    type: {
      type: String,
      default: "icon",
    },
  },
  data() {
    return {
      conditionIcon: CallSplitFilled,
      dialog: false,
      bodyStyle: { maxWidth: "1000px" },
    };
  },
  mixins: [formMixins],
  computed: {
    list() {
      return [...this.formConditions].map((item) => {
        item.condition = this.formatCondition(item);
        item.perform = this.formatPerform(item);
        return item;
      });
    },
    headers() {
      let { remove } = this;
      let list = [
        {
          key: "condition",
          title: "Conditions",
          render(row) {
            return h("div", {
              innerHTML: row.condition,
            });
          },
        },
        {
          key: "perform",
          title: "Perform",
          render(row) {
            return h("div", {
              innerHTML: row.perform,
            });
          },
        },
        {
          key: "actions",
          width: "100px",
          title: "Actions",
          render(row) {
            return h("div", { class: "d-flex" }, [
              h(FbAddCondition, { condition: row }),
              h(DeleteIconButton, { onClick: () => remove(row) }),
            ]);
          },
        },
      ];
      return list;
    },
  },
  methods: {
    formatCondition(condition) {
      condition = new FormCondition(condition);
      let conditions = {};
      try {
        conditions = JSON.parse(condition.Form_Condition);
      } catch (error) {
        console.error(error);
      }
      console.log("conditions", conditions);
      let html = ``;
      conditions.forEach((item) => {
        let field = this.findField(item.field);
        if (field != null) {
          html += `<b>${new FormField(field).Form_Label || "Field"} :</b> `;
          html += `${item.value} - ${item.condition}<br/>`;
          if (conditions.length != 1) {
            html += `<div class="bg-success text-white p-1 condition-chip">${item.type}</div>`;
            // html += h(NTag, { type: "success" }, item.type);
          }
        }
      });
      console.log(html);
      return html;
    },
    formatPerform(item) {
      let html = ``;
      let field = this.findField(new FormCondition(item).Field_Id);
      if (field != null) {
        html += `<b>${item.Action}:</b> ${
          new FormField(field).Form_Label || "Field"
        }`;
      }
      return html;
    },
    findField(id) {
      return this.formFields.find((item) => {
        return new FormField(item).Field_Id == id;
      });
    },
    remove(row) {
      console.log(row);
      const vm = this;
      vm.$confirm({
        message: "Are you sure, Do you want to remove this condition?",
        onConfirm: async () => {
          try {
            vm.$loader.show();
            let doc = new FormCondition({ ...row, Dml_Indicator: "D" });
            await formBuilderService.formCondition(doc);
            await vm.fetchFormConditions();
            vm.$loader.hide();
          } catch (error) {
            vm.handleError(error);
          }
        },
      });
    },
  },
};
</script>

<style>
.condition-chip {
  padding: 5px;
  width: 40px;
  font-size: 10px;
  text-align: center;
  color: white;
  border-radius: 5px;
}
</style>
