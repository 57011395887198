<template>
  <img :src="src" :style="imageStyle" />
</template>

<script>
import { ImagePrefix, getSettings } from "../../assets/js/common.helper";
export default {
  props: {
    field: {},
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: [],
    };
  },
  computed: {
    src() {
      const vm = this;
      let image = getSettings({ ...vm.field }).image;
      return ImagePrefix + image;
    },
  },
  methods: {},
};
</script>

<style></style>
