<template>
  <n-form-item :path="path" :showLabel="showLabel" class="flex-1">
    <n-checkbox
      v-bind="$attrs"
      v-model:checked="input"
      clearable
      @update:checked="onChecked"
      @keydown.enter.prevent
    >
      <span :style="labelStyle">
        {{ label }}
      </span>
    </n-checkbox>
  </n-form-item>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {},
    showLabel: { type: Boolean, default: false },
    path: {},
    customRules: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
    field: {
      type: Object,
      default: () => ({}),
    },
    label: { type: String, default: "" },
  },
  data() {
    return {
      input: "",
    };
  },
  computed: {
    rule() {
      let { rules } = this;
      const vm = this;
      let settings = this.settings || {};
      return {
        trigger: ["submit", "blur", "input"],
        required: settings.is_required || false,
        validator() {
          for (let rule of rules) {
            let msg = rule({ v: vm.input, ...vm.settings });
            if (msg) return new Error(msg);
          }
        },
      };
    },
  },
  methods: {
    onChecked(val) {
      this.$emit("input", val);
    },
  },
  mounted() {
    this.input = this.value == true;
  },
};
</script>

<style></style>
