<template>
  <label>Options</label>
  <n-dynamic-input
    v-model:value="options"
    :on-create="onCreate"
    :min="1"
    class="mb-5 mt-1"
  >
    <template #default="{ index, value }">
      <n-grid cols="4" x-gap="12">
        <n-gi span="3">
          <n-form-item
            ignore-path-change
            :show-label="false"
            :path="`Form_settings.options[${index}].name`"
            :rule="dynamicInputRule"
          >
            <n-input
              @input="onInput"
              placeholder="Name"
              v-model:value="value.name"
              type="text"
            />
          </n-form-item>
        </n-gi>
        <n-gi span="1">
          <n-form-item
            ignore-path-change
            :show-label="false"
            :path="`Form_settings.options[${index}].value`"
            :rule="dynamicInputRule"
          >
            <n-input
              v-model:value="value.value"
              @update:value="onInput"
              type="number"
              placeholder="Value"
              style="margin-right: 12px; width: 160px"
            />
          </n-form-item>
        </n-gi>
      </n-grid>
    </template>
  </n-dynamic-input>
</template>

<script>
import rulesConstant from "../../assets/constants/rules.constant";
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dynamicInputRule: rulesConstant.required,
      options: [
        {
          value: null,
          name: "",
        },
      ],
    };
  },
  methods: {
    onCreate() {
      return {
        value: null,
        name: "",
      };
    },
    onInput() {
      this.$emit("update:modelValue", this.options);
    },
  },
  beforeMount() {
    if (this.modelValue.length > 0) this.options = this.modelValue;
  },
};
</script>

<style>
</style>