<template>
  <n-button quaternary circle v-bind="$attrs">
    <template #icon>
      <n-icon>
        <EditRound></EditRound>
      </n-icon>
    </template>
  </n-button>
</template>

<script>
import { EditRound } from "@vicons/material";
export default {
  inheritAttrs: false,
  components: {
    EditRound,
  },
};
</script>

<style>
</style>