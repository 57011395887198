<template>
  <div class="text-editor">
    <div
      class="form-control"
      id="autocomplete-textarea"
      @input="onInput"
      :style="{ minHeight: height }"
      @click="handleClick"
      contenteditable="plaintext-only"
      v-html="model"
      ref="editor"
    ></div>
  </div>
</template>
  <script>
import $ from "jquery";
import "jquery-textcomplete";
export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: "280px",
    },
  },
  data() {
    return {
      model: "",
    };
  },
  watch: {
    items() {
      this.parseField();
    },
  },
  methods: {
    onInput() {
      console.log("html", this.$refs.editor.innerHTML);
      let html = this.$refs.editor.innerHTML;
      this.$emit("update:modelValue", html);
    },
    parseField() {
      let doc = document.createElement("div");
      doc.innerHTML = this.model;
      $(doc)
        .find("label")
        .each((i, item) => {
          let id = $(item).attr("data-id");
          let field = this.items.find((field) => {
            return field.id == id;
          });
          if (field != null) {
            $(item).html(field.name + '<a class="clear-btn">&#215;</a>');
          }
        });
      this.model = doc.innerHTML;
      this.$emit("update:modelValue", this.model);
    },
    handleClick(e) {
      if (e.target.matches(".clear-btn, .clear-btn *")) {
        $(e.target).parent()[0].remove();
      }
      this.onInput();
    },
    init() {
      const vm = this;
      vm.model = vm.modelValue;
      $(document).ready(() => {
        const AUTOCOMPLETE_ITEMS_DISPLAYED = 5;
        const SEARCH_CACHE = true;
        const SEARCH_MS_DEBOUNCE = 0;
        const SEARCH_TRIGGER_CHAR = "@";
        const SEARCH_TRIGGER_MIN_CHARS = 0;
        const SEARCH_MATCH_REGEX = new RegExp(
          `(^|\\s)${SEARCH_TRIGGER_CHAR}(\\w${
            SEARCH_TRIGGER_MIN_CHARS >= 0
              ? `{${SEARCH_TRIGGER_MIN_CHARS},}`
              : "*"
          }(?:\\s*\\w*)*)$`
        );
        const selectorId = "#autocomplete-textarea";
        const selectorElement = $(selectorId);
        let lastQuery = "";
        const onPastePlainText = (e) => {
          e.preventDefault();
          var pastedText;
          if (window.clipboardData && window.clipboardData.getData) {
            // IE
            pastedText = window.clipboardData.getData("Text");
          } else if (e.clipboardData && e.clipboardData.getData) {
            pastedText = e.clipboardData.getData("text/plain");
          }
          document.execCommand("insertHTML", false, pastedText);
        };
        selectorElement[0].addEventListener("paste", onPastePlainText);
        const mentionStrategy = {
          cache: SEARCH_CACHE,
          match: SEARCH_MATCH_REGEX,
          search: (query, callback) => {
            lastQuery = query.toLowerCase();
            let data = vm.items.filter((item) => {
              return item.name.toLowerCase().indexOf(lastQuery) != -1;
            });
            callback(data);
          },
          template: (hit) => templates.resultDisplay(hit),
          replace: (hit) => templates.selectedResult(hit),
        };

        selectorElement
          .textcomplete([mentionStrategy], {
            debounce: SEARCH_MS_DEBOUNCE,
            maxCount: AUTOCOMPLETE_ITEMS_DISPLAYED,
            adapter: $.fn.textcomplete.HTMLContentEditable,
          })
          .on({
            "textComplete:select": function () {
              vm.onInput();
            },
          });
        //   $(".clear-btn").click(e => {
        //     $(e.target)
        //       .parent()[0]
        //       .remove();
        //   });
      });
      const templates = {
        selectedResult: (hit) =>
          ` <label contentEditable="false" spellcheck="false" class="tag-item" @click="remove" data-id="${hit.id}">${hit.name} <a class="clear-btn">&#215;</a></label> `,
        resultDisplay: (hit) => {
          const regex = new RegExp("(".concat(hit.searchQuery, ")"), "i");
          const fullName = hit.name.replace(regex, "<em>$1</em>");
          return fullName;
        },
      };
      vm.$nextTick(() => {
        $("div[contenteditable]").keydown(function () {
          // if (e.keyCode === 13) {
          //   window.document.execCommand("insertHTML", false, "\n");
          //   return false;
          // }
        });
      });
    },
    initFormula(value) {
      const vm = this;
      vm.$refs.editor.innerHTML = value;
      vm.model = value;
      this.parseField();
    },
  },
  mounted() {
    this.init();
    this.parseField();
  },
};
</script>
  
  <style lang="scss">
.clear-btn {
  background: white;
  border-radius: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  border-radius: 10px;
}
small {
  color: #83ba52 !important;
}
mark {
  background-color: #f5d67b;
}

#autocomplete-textarea {
  outline: none;
  line-height: 38px;
  color: #000;
  border: 1px solid lightgrey;
  overflow-y: auto;
  max-height: 500px;
  padding: 10px;
}
#autocomplete-textarea a {
  text-decoration: none;
  font-weight: 400;
  color: #181818;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000000 !important;
  float: left;
  min-width: 160px;
  padding: 0px;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}
.dropdown-menu .textcomplete-item a:hover {
  color: white;
}
.dropdown-menu .textcomplete-item {
  width: 100%;
  cursor: pointer;
  padding: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  border-bottom: 1px solid lightgray;
}
.dropdown-menu .textcomplete-item:hover {
  background: #3949ab;
  color: white !important;
}
.dropdown-menu .textcomplete-item:last-child {
  border-bottom: 0px;
}
</style>
  <style lang="scss">
.text-editor {
  .tag-item,
  .tag-item:visited {
    font-weight: 400;
    padding: 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    cursor: default;
  }
  .tag-item:hover {
    text-decoration: none;
  }
  .tag-item {
    color: white;
    font-weight: 400;
    font-size: 100%;
    border-radius: 5px;
    background: #3949ab;
    padding: 5px 10px;
  }
}
</style>
  