<template>
  <div>
    <icon-button size="small" @click="dialog = true" v-if="type == 'icon'">
      <EditRound></EditRound>
    </icon-button>
    <add-button @click="dialog = true" v-else> Add Column</add-button>
    <n-modal
      v-model:show="dialog"
      preset="card"
      :style="bodyStyle"
      :bordered="false"
      :closeOnEsc="false"
      :maskClosable="false"
      :closable="false"
    >
      <form-wrapper @onSubmit="submit()" :rules="rules" :model="form">
        <input-field path="label" label="Label" v-model:value="form.label" />
        <select-field
          path="type"
          label="Type"
          v-model:value="form.type"
          :options="types"
        />
        <check-box-field
          label="Is Required?"
          v-model:value="form.is_required"
          v-if="false"
          @input="form.is_required = $event"
        />
        <check-box-field
          label="Is Editable?"
          v-model:value="form.is_editable"
          @input="form.is_editable = $event"
        />
        <dynamic-tag-field
          v-if="form.type == 'Select'"
          path="Form_settings.options"
          v-model:value="form.options"
          label="Options"
        />

        <div class="d-flex mt-3">
          <div class="flex-1"></div>
          <n-button color="red" class="mr-3" quaternary @click="dialog = false"
            >Close</n-button
          >
          <primary-button attr-type="submit">Submit</primary-button>
        </div>
      </form-wrapper>
    </n-modal>
  </div>
</template>

<script>
import { initForm } from "../../assets/js/common.helper";
import rulesConstant from "../../assets/constants/rules.constant";
import AddButton from "../ui/buttons/AddButton.vue";
import IconButton from "../ui/buttons/IconButton.vue";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import CheckBoxField from "../ui/form/CheckBoxField.vue";
import FormWrapper from "../ui/form/FormWrapper.vue";
import InputField from "../ui/form/InputField.vue";
import SelectField from "../ui/form/SelectField.vue";
import { EditRound } from "@vicons/material";
import DynamicTagField from "../ui/form/DynamicTagField.vue";
export default {
  props: {
    type: {
      type: String,
      default: "button",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: null,
    },
  },
  components: {
    IconButton,
    AddButton,
    FormWrapper,
    PrimaryButton,
    InputField,
    CheckBoxField,
    SelectField,
    EditRound,
    DynamicTagField,
  },
  emits: ["update", "add"],
  data() {
    return {
      dialog: false,
      bodyStyle: { maxWidth: "400px" },
      form: {
        label: "",
        type: "Text",
        is_editable: false,
        is_required: false,
        options: [],
      },
    };
  },
  computed: {
    types() {
      return ["Text", "Number", "Image", "Select"].map((label) => {
        return { label, value: label };
      });
    },
    rules() {
      return {
        label: rulesConstant.required,
        type: rulesConstant.required,
        is_required: rulesConstant.required,
        is_editable: rulesConstant.required,
      };
    },
    title() {
      return this.type == "icon" ? "Update Column" : "Add Column";
    },
  },
  watch: {
    dialog() {
      initForm(this.form, this.item);
    },
  },
  methods: {
    submit() {
      console.log(this.index);
      if (this.index == null) {
        this.$emit("add", { ...this.form });
      } else {
        this.$emit("update", { ...this.form });
      }
      this.form = {
        label: "",
        type: "Text",
        is_editable: false,
        is_required: false,
      };
      this.dialog = false;
    },
  },
};
</script>

<style></style>
