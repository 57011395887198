<template>
  <n-grid :cols="5" x-gap="12">
    <n-gi :span="1">
      <select-field
        v-model:value="form.method"
        path="Form_settings.api.method"
        @input:value="$emit('update:modelValue', form)"
        label="Method"
        :options="methods"
      />
    </n-gi>
    <n-gi :span="4">
      <input-field
        v-model:value="form.url"
        path="Form_settings.api.url"
        @input="$emit('update:modelValue', form)"
        label="URL"
      ></input-field>
    </n-gi>
  </n-grid>
  <div class="mb-5">
    <div class="title mb-2">
      {{ form.method == "GET" ? "Query Parameters" : "Body Parameters" }}
    </div>
    <n-dynamic-input
      v-model:value="form.body"
      item-style="margin-bottom: 10px;"
      :on-create="onCreate"
      #="{ index, value }"
    >
      <div class="dynamic-body">
        <div class="mt-1">
          <n-checkbox v-model:checked="form.body[index].is_field">
            Is form field?
          </n-checkbox>
        </div>
        <n-form-item
          ignore-path-change
          :show-label="false"
          :path="`Form_settings.api.body[${index}].name`"
          :rule="dynamicInputRule"
        >
          <n-input
            v-model:value="form.body[index].name"
            placeholder="Name"
            @input="$emit('update:modelValue', form)"
            @keydown.enter.prevent
          />
        </n-form-item>
        <div style="height: 34px; line-height: 34px; margin: 0 8px"></div>
        <n-form-item
          ignore-path-change
          :show-label="false"
          :path="`Form_settings.api.body[${index}].value`"
          :rule="dynamicInputRule"
        >
          <n-select
            v-model:value="form.body[index].value"
            v-if="form.body[index].is_field == true"
            placeholder="Value"
            :options="fieldOptions"
            @update:value="$emit('update:modelValue', form)"
            @keydown.enter.prevent
          />
          <n-input
            v-else
            v-model:value="form.body[index].value"
            placeholder="Value"
            @input="$emit('update:modelValue', form)"
            @keydown.enter.prevent
          />
        </n-form-item>
      </div>
    </n-dynamic-input>
    <n-grid :cols="3" x-gap="12" class="mt-2">
      <n-gi>
        <input-field
          v-model:value="form.list_key"
          path="Form_settings.api.list_key"
          @input="$emit('update:modelValue', form)"
          label="List Key"
        />
      </n-gi>
      <n-gi>
        <input-field
          v-model:value="form.name_key"
          path="Form_settings.api.name_key"
          @input="$emit('update:modelValue', form)"
          label="Name Key"
        />
      </n-gi>
      <n-gi>
        <input-field
          v-model:value="form.value_key"
          path="Form_settings.api.value_key"
          @input="$emit('update:modelValue', form)"
          label="Value Key"
        />
      </n-gi>
    </n-grid>
  </div>
</template>

<script>
import { initForm } from "../../assets/js/common.helper";
import rulesConstant from "../../assets/constants/rules.constant";
import InputField from "../ui/form/InputField.vue";
import SelectField from "../ui/form/SelectField.vue";
import formMixins from "../../mixins/form.mixins";
export default {
  props: {
    modelValue: {},
    fieldId: {},
  },
  components: { SelectField, InputField },
  mixins: [formMixins],
  data() {
    return {
      form: {
        method: "get",
        url: "",
        body: [],
        list_key: "",
        name_key: "",
        value_key: "",
      },
      dynamicInputRule: rulesConstant.required,
    };
  },
  computed: {
    fieldOptions() {
      let list = [...this.formFields].filter((i) => i.Field_Id != this.fieldId);
      return list.map((i) => ({ label: i.Form_Label, value: i.Field_Id }));
    },
    methods() {
      return [
        {
          label: "POST",
          value: "post",
        },
        {
          label: "GET",
          value: "get",
        },
      ];
    },
  },
  methods: {
    onCreate() {
      return {
        name: "",
        value: "",
        is_field: false,
      };
    },
  },
  mounted() {
    initForm(this.form, this.modelValue);
  },
};
</script>

<style lang="scss">
.dynamic-body {
  display: flex;
  flex: 1;
  .n-form-item {
    flex: 1;
  }
}
</style>