<template>
  <div class="d-flex">
    <select-field
      :label="label"
      :placeholder="settings.placeholder"
      :help="settings.help_text"
      :options="options"
      :field="field"
    ></select-field>
    <fb-field-config :field="field"></fb-field-config>
  </div>
</template>

<script>
import { getSettings } from "../../assets/js/common.helper";
import SelectField from "../ui/form/SelectField.vue";
import FbFieldConfig from "./FbFieldConfig.vue";
export default {
  props: { field: {} },
  components: { FbFieldConfig, SelectField },
  computed: {
    label() {
      return this.field.Form_Label;
    },
    settings() {
      return getSettings({ ...this.field });
    },
    options() {
      let options = this.settings.options || [];
      return options.map((label) => ({ label, value: label }));
    },
  },
};
</script>

<style>
</style>