<template>
  <n-button strong v-bind="$attrs" color="red" ghost>
    <slot> Close </slot>
  </n-button>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style>
</style>