<template>
  <n-button strong v-bind="$attrs" type="info">
    <slot></slot>
  </n-button>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style>
</style>