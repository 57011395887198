<template>
  <div>
    <icon-button size="small" @click="dialog = true" v-if="type == 'icon'">
      <EditRound></EditRound>
    </icon-button>
    <add-button @click="dialog = true" v-else> Add Row</add-button>
    <n-modal
      v-model:show="dialog"
      preset="card"
      :style="bodyStyle"
      :bordered="false"
      :closeOnEsc="false"
      :maskClosable="false"
      :closable="false"
    >
      <n-grid :x-gap="12" :cols="3" :key="item.ts">
        <n-gi v-for="(item, i) in columnList" :key="i">
          <select-field
            v-if="item.type == 'Select'"
            :label="item.label"
            :options="item.options"
            v-model:value="form[item.label]"
          ></select-field>
          <input-field
            v-else
            :type="item.type.toLowerCase()"
            :label="item.label"
            v-model:value="form[item.label]"
          />
        </n-gi>
      </n-grid>

      <div class="d-flex mt-3">
        <div class="flex-1"></div>
        <n-button color="red" class="mr-3" quaternary @click="dialog = false"
          >Close</n-button
        >
        <primary-button attr-type="submit" @click="submit()" :loading="loading"
          >Submit</primary-button
        >
      </div>
    </n-modal>
  </div>
</template>

<script>
import rulesConstant from "../../assets/constants/rules.constant";
import AddButton from "../ui/buttons/AddButton.vue";
import IconButton from "../ui/buttons/IconButton.vue";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import InputField from "../ui/form/InputField.vue";
import { EditRound } from "@vicons/material";
import { getSettings } from "../../assets/js/common.helper";
import formBuilderService from "../../assets/services/form.builder.service";
import formMixins from "../../mixins/form.mixins";
import SelectField from "../ui/form/SelectField.vue";
export default {
  props: {
    type: {
      type: String,
      default: "button",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: null,
    },
    field: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    IconButton,
    AddButton,
    PrimaryButton,
    InputField,
    EditRound,
    SelectField,
  },
  data() {
    return {
      dialog: false,
      bodyStyle: { maxWidth: "800px" },
      loading: false,
      form: {},
    };
  },
  mixins: [formMixins],
  computed: {
    columns() {
      let columns = getSettings({ ...this.field }).columns || [];
      return columns.map((i) => i.label);
    },
    columnList() {
      let list = getSettings({ ...this.field }).columns || [];
      return list.map((i) => {
        if (i.options) {
          i.options = i.options.map((value) => ({ value, label: value }));
        }
        return i;
      });
    },
    types() {
      return ["Text", "Number", "Image"].map((label) => {
        return { label, value: label };
      });
    },
    rules() {
      return {
        label: rulesConstant.required,
        type: rulesConstant.required,
        is_required: rulesConstant.required,
        is_editable: rulesConstant.required,
      };
    },
    title() {
      return this.type == "icon" ? "Update Column" : "Add Column";
    },
  },
  watch: {
    dialog() {
      this.columns.forEach((i) => {
        this.form[i] = this.item[i] || "";
      });
    },
  },
  methods: {
    async submit() {
      const vm = this;
      let settings = getSettings(this.field);
      let rows = settings.table_rows || [];
      console.log(vm.index);
      if (vm.index != null) {
        rows[vm.index] = { ...vm.form };
      } else {
        rows.push(vm.form);
      }
      settings.table_rows = rows;
      try {
        vm.loading = true;
        let field = {
          ...this.field,
          Form_settings: JSON.stringify(settings),
          Dml_Indicator: "UP",
        };
        let { status, msg } = (await formBuilderService.formField(field)).data;
        if (!status) {
          vm.loading = false;
          vm.$alert(msg);
          return;
        }
        vm.form = {};
        vm.loading = false;
        vm.dialog = false;
        vm.fetchFormFields();
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
};
</script>

<style></style>
