<template>
  <n-space justify="center">
    <n-dropdown
      trigger="hover"
      size="small"
      :options="options"
      @select="handleSelect"
    >
      <add-button size="small" class="my-3">Add Field</add-button>
    </n-dropdown>
    <fb-field-settings ref="settings"></fb-field-settings>
  </n-space>
</template>

<script>
import {
  fieldTypes,
  nonSettingFields,
} from "../../assets/constants/form.builder.constant.js";
import AddButton from "../ui/buttons/AddButton.vue";
import FbFieldSettings from "./FbFieldSettings.vue";
import formBuilderService from "../../assets/services/form.builder.service";
import {
  FormField,
  FormMaster,
  FormStep,
} from "../../assets/model/form.master.model";
import formMixins from "../../mixins/form.mixins";
export default {
  props: { parent: { type: Number, default: 0 } },
  components: { AddButton, FbFieldSettings },
  data() {
    return {
      options: fieldTypes,
      field: {
        Field_Type: "",
      },
    };
  },
  mixins: [formMixins],
  methods: {
    async handleSelect(type) {
      const vm = this;
      let parentList = [...this.formStepFields]
        .filter((i) => i.Parent == this.parent)
        .map((i) => ({ ...i }));
      let position = parentList.length + 1;
      if (nonSettingFields.indexOf(type) == -1) {
        vm.$refs.settings.initAddField({ type, parent: vm.parent, position });
        return;
      }
      try {
        vm.$loader.show();
        let formId = new FormMaster(vm.formData).Formid;
        let doc = new FormField({
          Field_Type: type,
          Dml_Indicator: "IS",
          Form_id: formId,
          Parent: vm.parent,
          Field_Position: position,
        });
        if (vm.isStepForm) {
          doc.Step_id = new FormStep(vm.formStep).Step_id;
        }
        let { status, msg } = (await formBuilderService.formField(doc)).data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert(msg);
          return;
        }
        await vm.fetchFormFields();
        vm.$loader.hide();
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    console.log(JSON.stringify(fieldTypes.map((i) => i.label)));
  },
};
</script>

<style>
.n-dropdown-menu {
  max-height: 250px;
  overflow-y: auto;
}
</style>