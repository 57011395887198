<template>
  <div class="d-flex items-center mb-2 pb-2">
    <div class="flex-1" :style="labelStyle">
      {{ field.Form_Label }}
    </div>
    <fb-field-config :field="field"></fb-field-config>
  </div>
</template>
  
  <script>
import formMixins from "../../mixins/form.mixins";
import FbFieldConfig from "./FbFieldConfig.vue";
import { FormField } from "../../assets/model/form.master.model";
export default {
  name: "FormSection",
  props: {
    field: {},
  },
  mixins: [formMixins],
  components: {
    FbFieldConfig,
  },
  data() {
    return {
      fieldList: [],
      title: "",
    };
  },
  watch: {
    formStepFields() {
      this.initFields();
    },
  },
  computed: {
    parent() {
      return new FormField(this.field).Field_Id;
    },
  },
  methods: {
    initFields() {
      this.fieldList = [...this.formStepFields]
        .filter((i) => i.Parent == this.parent)
        .map((i) => ({ ...i }));
    },
    async changeTitle() {},
  },
  mounted() {
    this.initFields();
  },
};
</script>
  