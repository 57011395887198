<template>
  <n-form-item
    :label="label"
    :label-style="labelStyle"
    :path="path"
    class="flex-1"
    :rule="rule"
  >
    <template v-slot:label>
      <span class="mr-1">
        {{ label }}
      </span>
      <help-text v-if="help"> {{ help }}</help-text>
    </template>
    <n-input
      v-bind="$attrs"
      style="width: 100%"
      v-model:value="input"
      clearable
      :accept="accept"
      @keydown.enter.prevent
      type="file"
    />
  </n-form-item>
</template>

<script>
import HelpText from "../HelpText.vue";

export default {
  components: { HelpText },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: null,
    },
    accept: {
      type: String,
      default: "",
    },
    help: { type: String, default: "" },
    path: {},
    customRules: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: { type: String, default: "" },
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      input: null,
    };
  },
  computed: {
    rule() {
      let { rules } = this;
      const vm = this;
      let settings = this.settings || {};
      return {
        trigger: ["submit", "blur", "input"],
        required: settings.is_required || false,
        validator() {
          for (let rule of rules) {
            let msg = rule({ v: vm.input, ...vm.settings });
            if (msg) return new Error(msg);
          }
        },
      };
    },
  },
  watch: {
    input(val) {
      this.input = val || null;
    },
  },
  mounted() {
    this.input = this.value || null;
  },
};
</script>

<style></style>
