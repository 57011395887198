<template>
  <div v-if="isShow">
    <component
      v-bind="bind"
      v-model:value="input"
      :field="field"
      :is="component"
      @input="onInput"
      @input:value="onInput"
    />
  </div>
</template>

<script>
import { textFields } from "../../assets/constants/form.builder.constant";
import InputField from "../ui/form/InputField.vue";
import DateField from "../ui/form/DateField.vue";
import RadioGroupField from "../ui/form/RadioGroupField.vue";
import CheckBoxField from "../ui/form/CheckBoxField.vue";
import SelectField from "../ui/form/SelectField.vue";
import SignatureField from "../ui/form/SignatureField.vue";
import FpSection from "./FpSection.vue";
import FpGridLayout from "./FpGridLayout.vue";
import rulesConstant from "../../assets/constants/rules.constant";
import { FormCondition, FormField } from "../../assets/model/form.master.model";
import formMixins from "../../mixins/form.mixins";
import UploadField from "../ui/form/UploadField.vue";
import { getSettings } from "../../assets/js/common.helper";
import _ from "lodash";
import FpExplanation from "./FpExplanation.vue";
import FpRichEditor from "./FpRichEditor.vue";
import FpContainer from "./FpContainer.vue";
import CheckBoxGroupField from "../ui/form/CheckBoxGroupField.vue";
import FpQuestionerResult from "./FpQuestionerResult.vue";
import FpTable from "./FpTable.vue";
import FpImage from "./FpImage.vue";
import FpImageUploader from "./FpImageUploader.vue";
import FpHeaderLayout from "./FpHeaderLayout.vue";
export default {
  components: {
    InputField,
    DateField,
    RadioGroupField,
    CheckBoxField,
    SelectField,
    SignatureField,
    FpSection,
    FpGridLayout,
    UploadField,
    FpExplanation,
    CheckBoxGroupField,
    FpRichEditor,
    FpContainer,
    FpQuestionerResult,
    FpTable,
    FpImage,
    FpHeaderLayout,
    FpImageUploader,
  },
  props: {
    field: {},
    parentPath: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      input: "",
    };
  },
  mixins: [formMixins],
  computed: {
    dynamicOptions() {
      return this.formDynamicOptions[this.field.Field_Id];
    },
    isTextField() {
      let skip = ["date", "datetime", "time"];
      let fields = textFields.filter((i) => skip.indexOf(i) == -1);
      return ["0", ...fields].indexOf(this.type) != -1;
    },
    component() {
      let { is, isTextField } = this;
      if (isTextField) return `input-field`;
      if (is(["date", "datetime", "time"])) return "date-field";
      if (is(["radio"])) return "radio-group-field";
      if (is(["checkbox"])) return "check-box-field";
      if (is(["checkbox-group", "questioner"])) return "check-box-group-field";
      if (is(["select"])) return "select-field";
      if (is(["file-upload"])) return "upload-field";
      if (is(["signature"])) return "signature-field";
      if (is(["section"])) return "fp-section";
      if (is(["grid"])) return "fp-grid-layout";
      if (is(["paragraph"])) return "fp-explanation";
      if (is(["rich-editor"])) return "fp-rich-editor";
      if (is(["container"])) return "fp-container";
      if (is(["questioner-result"])) return "fp-questioner-result";
      if (is(["table"])) return "fp-table";
      if (is(["image"])) return "fp-image";
      if (is(["image-uploader"])) return "fp-image-uploader";
      if (is(["header", "footer"])) return "fp-header-layout";
      return "div";
    },
    settings() {
      return getSettings({ ...this.field });
    },
    bind() {
      let { field, rules, parentPath, settings, dynamicOptions } = this;
      let label = field.Form_Label;
      let options = settings.options;
      if (options) {
        options = options.map((value) => {
          if (typeof value == "string") return { label: value, value };
          return value;
        });
      }
      if (settings.is_dynamic == true) options = dynamicOptions;
      let type = this.type;
      if (type == "phone") type = "number";
      return {
        label,
        rules,
        parentPath,
        options,
        field,
        type,
        settings: this.settings,
        path: new FormField(field).Field_Id,
        placeholder: settings.placeholder,
        help: settings.help_text,
        multiple: settings.is_multiple,
        required: settings.is_required,
        file_type: settings.file_type,
        html: settings.html,
      };
    },
    rules() {
      let { is_required, min_char, max_char, is_future, character } =
        this.settings;
      let rules = [];
      let { type } = this;
      let {
        required,
        minChar,
        maxChar,
        number,
        minNumber,
        alpha,
        maxNumber,
        disableFutureDate,
        disableFutureDateTime,
        alphaNumeric,
      } = rulesConstant.rules;
      if (is_required == true) rules.push(required);
      let typeRule = rulesConstant.rules[type];
      if (typeRule) rules.push(typeRule);
      if (character == "numeric") rules.push(number);
      if (character == "alpha") rules.push(alpha);
      if (character == "alpha_numeric") rules.push(alphaNumeric);
      let isNumberField = ["number", "currency"].indexOf(type) != -1;
      if (min_char != undefined) {
        if (isNumberField) {
          rules.push(minNumber);
        } else {
          rules.push(minChar);
        }
      }
      if (max_char != undefined) {
        if (isNumberField) {
          rules.push(maxNumber);
        } else {
          rules.push(maxChar);
        }
      }
      if (is_future == false && type == "date") {
        rules.push(disableFutureDate);
      }
      if (is_future == false && type == "datetime") {
        rules.push(disableFutureDateTime);
      }
      return rules;
    },
    type() {
      return this.field.Field_Type;
    },
    id() {
      return new FormField(this.field).Field_Id;
    },
    isNotVisible() {
      return this.settings.is_visible == false;
    },
    isShow() {
      if (this.isNotVisible) return false;
      let condition = this.formConditions.find((item) => {
        return new FormCondition(item).Field_Id == this.id;
      });
      if (condition) {
        let conditions = condition.conditions || [];
        let action = condition.Action;
        let ands = conditions.filter((item) => {
          return item.type == "AND";
        });
        let ors = conditions.filter((item) => {
          return item.type == "OR";
        });
        let andPassed = [];
        let orPassed = [];
        for (let and of ands) {
          and = { ...and };
          let id = and.field;
          let andField = [...this.formFields].find((item) => {
            return new FormCondition(item).Field_Id == and.field;
          });
          let value = this.form[id];
          if (this.panel && value == undefined) id = `${id}_${this.panel}`;
          value = this.form[id];
          if (andField && andField.Field_Type == "checkbox") {
            value = value == true ? "Checked" : "Unchecked";
          }
          if (this.isConditionPassed(value, and)) andPassed.push("yes");
        }
        for (let or of ors) {
          or = { ...or };
          let orField = [...this.formFields].find((item) => {
            return new FormCondition(item).Field_Id == or.field;
          });
          let id = or.field;
          let value = this.form[id];
          if (this.panel && value == undefined) id = `${id}_${this.panel}`;
          value = this.form[id];
          if (orField && orField.Field_Type == "checkbox") {
            value = value == true ? "Checked" : "Unchecked";
          }
          if (this.isConditionPassed(value, or)) orPassed.push("yes");
        }
        let isPassed = andPassed.length == ands.length || orPassed.length != 0;
        if (action == "Show") {
          return isPassed;
        }
        if (action == "Hide") {
          return !isPassed;
        }
      }
      return true;
    },
  },
  methods: {
    isConditionPassed(value, and) {
      let isPassed = false;
      switch (and.condition) {
        case "Selected":
          if (and.value instanceof Array) {
            isPassed = this.areArraysEqual(value || [], and.value || []);
          } else {
            isPassed = value == and.value;
          }
          break;
        case "Is Not Selected":
          if (and.value instanceof Array) {
            isPassed = !this.areArraysEqual(value || [], and.value || []);
          } else {
            isPassed = value != and.value;
          }
          break;
        case "Starts With":
          isPassed = value?.toString().startsWith(and.value.toString());
          break;
        case "Ends With":
          isPassed = value?.toString().endsWith(and.value.toString());
          break;
        case "Is":
          isPassed = value == and.value;
          break;
        case "Is Not":
          isPassed = value != and.value;
          break;
      }
      return isPassed;
    },
    onInput(val) {
      if (val == null) return;
      if (typeof val == "object" && val.target != undefined) {
        return;
      }
      let form = { ...this.form };
      const previousForm = JSON.parse(JSON.stringify(this.form));
      form[this.id] = val;
      let label = this.field.Form_Label;
      let json = this.$store.getters.formJson;
      let path = label;
      let formFiles = this.$store.getters.formFiles;
      if (label && this.parentPath) {
        _.set(json, `${this.parentPath}.["${label}"]`, val);
        path = `${this.parentPath}.["${label}"]`;
      } else if (label) {
        console.log(label);
        _.set(json, `["${label}"]`, val);
      }
      console.log(json);

      if (["file-upload", "image-uploader"].indexOf(this.type) != -1) {
        formFiles[this.id] = path;
      }
      this.$store.commit("setFormFiles", formFiles);
      this.$store.commit("setForm", form);
      this.$store.commit("setFormJson", json);
      let dynamicFields = this.formFields
        .filter((i) => {
          let settings = getSettings({ ...i });
          return settings.is_dynamic == true;
        })
        .filter((i) => i.Field_Id != this.field.Field_Id);
      dynamicFields.forEach((field) => {
        this.fetchDynamicOptions({
          isInput: true,
          field,
          previousForm,
        });
      });
    },
    is(types) {
      return types.indexOf(this.type) != -1;
    },
    areArraysEqual(array1, array2) {
      if (array1.length !== array2.length) {
        return false;
      }
      const sortedArray1 = array1.slice().sort();
      const sortedArray2 = array2.slice().sort();
      return sortedArray1.every(
        (value, index) => value === sortedArray2[index]
      );
    },
  },
  beforeMount() {
    this.input = this.form[this.id] || "";
  },
  mounted() {
    this.fetchDynamicOptions({
      isInput: false,
      field: { ...this.field },
    });
  },
};
</script>

<style></style>
