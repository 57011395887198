<template>
  <layout class="p-5 form-preview">
    <please-wait v-if="loading"></please-wait>
    <div
      v-else-if="error"
      class="d-flex justify-center items-center"
      style="height: 100vh"
    >
      <n-result status="error" title="Error" :description="error"></n-result>
    </div>
    <template v-else>
      <div class="print-end mb-3">
        <primary-button @click="printPage"> Print </primary-button>
      </div>

      <div class="px-5">
        <fp-steps v-if="isStepForm" :currentStep="currentStep"></fp-steps>
        <n-form ref="form" :model="form">
          <div class="form-header" :style="headerStyle">
            <f-preview
              v-for="field in headerFields"
              :field="field"
              v-bind:key="field.Field_Id"
            />
          </div>
          <table style="width: 100%">
            <thead>
              <tr>
                <td>
                  <div id="form-header" class="header-space">&nbsp;</div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="py-5">
                    <template v-if="isPrint">
                      <f-print
                        v-for="field in bodyFields"
                        :field="field"
                        v-bind:key="field.Field_Id"
                      />
                    </template>
                    <template v-else>
                      <f-preview
                        v-for="field in bodyFields"
                        :field="field"
                        v-bind:key="field.Field_Id"
                      />
                    </template>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div id="form-footer" class="footer-space">&nbsp;</div>
                </td>
              </tr>
            </tfoot>
          </table>
          <div class="form-footer" :style="footerStyle">
            <f-preview
              v-for="field in footerFields"
              :field="field"
              v-bind:key="field.Field_Id"
            />
          </div>

          <div class="d-flex justify-space-between mt-3">
            <outlined-button @click="initFormStep(-1)" v-if="currentStep != 1"
              >Previous</outlined-button
            >

            <div class="flex-1"></div>

            <primary-button
              :loading="isLoading"
              @click="formpreview()"
              class="mr-3"
            >
              {{ !isNotSubmit ? "Preview" : "" }}
            </primary-button>

            <primary-button :loading="isLoading" @click="validate()">
              {{ !isNotSubmit ? "Submit" : "Next" }}
            </primary-button>
          </div>
        </n-form>
      </div>
    </template>
  </layout>
</template>

<script>
import formMixins from "../mixins/form.mixins";
import FpSteps from "../components/formPreview/FpSteps.vue";
import OutlinedButton from "../components/ui/buttons/OutlinedButton.vue";
import PrimaryButton from "../components/ui/buttons/PrimaryButton.vue";
import formBuilderService from "../assets/services/form.builder.service";
import { FormPublish } from "../assets/model/form.publish.model";
import { FormPreviewMaster } from "../assets/model/form.preview.model";
import { FormSubmitData } from "../assets/model/form.master.model";
import { getSettings, parseJson } from "../assets/js/common.helper";
import _ from "lodash";
export default {
  components: { FpSteps, OutlinedButton, PrimaryButton },
  data() {
    return {
      loading: true,
      error: "",
      currentStep: 1,
      isLoading: false,
      fieldList: [],
      version: {},
      formDoc: {},
      isPrint: false,
    };
  },

  mixins: [formMixins],
  computed: {
    isEditForm() {
      return this.$route.name == "edit-form";
    },
    isNotSubmit() {
      let count = this.formSteps.length;
      return count > this.currentStep;
    },
    formId() {
      return this.$route.params.id;
    },
    isForm() {
      return this.$route.name == "form";
    },
    query() {
      let ids = [
        "branchid",
        "hospitalid",
        "patientid",
        "service_id",
        "test_id",
        "visit_reportid",
        "work_order_details_id",
      ];
      let doc = this.isEditForm ? this.formDoc : this.$route.query;
      let query = {};
      ids.forEach((id) => {
        if (doc[id] != undefined) {
          query[id] = this.isEditForm ? doc[id] : atob(doc[id]);
        }
      });
      return query;
    },
    bodyFields() {
      return this.fieldList.filter(
        (i) => ["header", "footer"].indexOf(i.Field_Type) == -1
      );
    },
    headerFields() {
      return this.fieldList.filter((i) => i.Field_Type == "header");
    },
    footerFields() {
      return this.fieldList.filter((i) => i.Field_Type == "footer");
    },
    headerStyle() {
      if (this.headerFields.length > 0) {
        let height = getSettings(this.headerFields[0]).height;
        return { height };
      }
      return {};
    },
    footerStyle() {
      if (this.footerFields.length > 0) {
        let height = getSettings(this.footerFields[0]).height;
        return { height };
      }
      return {};
    },
  },
  watch: {
    formStepFields(val) {
      this.fieldList = [...val]
        .filter((i) => i.Parent == 0)
        .map((i) => ({ ...i }));
    },
  },
  methods: {
    async printPage() {
      if (this.headerFields.length > 0) {
        let height = getSettings(this.headerFields[0]).height;
        document.getElementById("form-header").style.height = height;
      }
      if (this.footerFields.length > 0) {
        let height = getSettings(this.footerFields[0]).height;
        document.getElementById("form-footer").style.height = height;
      }
      this.isPrint = true;
      this.$loader.show();
      await this.sleep(2000);
      this.$loader.hide();
      await this.sleep(1000);
      window.print();
      this.isPrint = false;
    },
    sleep(duration) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, duration);
      });
    },
    async fetchPreviewForm() {
      const vm = this;
      try {
        vm.loading = true;
        await vm.fetchFormData(vm.formId);
        if (vm.isStepForm) {
          await vm.fetchFormSteps();
          if (vm.formSteps.length) {
            vm.$store.commit("setFormStep", vm.formSteps[0]);
          }
        }
        await vm.fetchFormFields();
        await vm.fetchFormConditions();
        vm.initFormValues();
        vm.loading = false;
      } catch (error) {
        vm.error = error.message;
        vm.loading = false;
      }
    },
    async fetchForm() {
      const vm = this;
      try {
        vm.loading = true;
        let formId = vm.formId;
        let versionId = 0;
        if (vm.isEditForm) {
          let { status, msg, data } = (
            await formBuilderService.getEditForm({
              id: vm.formId,
            })
          ).data;
          if (status == false) {
            vm.error = msg;
            vm.loading = false;
            return;
          }
          vm.formDoc = data.Table[0];
          formId = vm.formDoc.formid;
          versionId = vm.formDoc.versionid;
          let form = parseJson(vm.formDoc.responsedata);
          let formJson = parseJson(vm.formDoc.responsedataweb);
          this.$store.commit("setFormJson", formJson);
          vm.$store.commit("setForm", form);
        }
        let doc = new FormPublish({
          Form_id: formId,
          Dml_Indicator: "GLV",
          Version_Id: versionId,
        });
        let { data, status, msg } = (await formBuilderService.formPublish(doc))
          .data;
        if (status) {
          let item = data.Table[0];
          if (item) {
            vm.version = item;
            let form = JSON.parse(item.File_Location);
            form = new FormPreviewMaster(form);
            vm.$store.commit("setFormData", form);
            vm.$store.commit("setFormSteps", form.steps);
            vm.$store.commit("setFormFields", form.fields);
            vm.$store.commit("setFormConditions", form.conditions);
            if (vm.formSteps.length) {
              vm.$store.commit("setFormStep", vm.formSteps[0]);
            }
          }
          vm.initFormValues();

          vm.loading = false;
        } else {
          vm.error = msg;
        }
      } catch (error) {
        vm.error = error.message;
        vm.loading = false;
      }
    },
    validate() {
      this.$refs.form.validate((errors) => {
        if (!errors) {
          this.submit();
        }
      });
    },
    async submit() {
      const vm = this;
      let count = vm.formSteps.length;
      if (count > vm.currentStep) {
        this.initFormStep(1);
        return;
      }
      if (!vm.isForm && !vm.isEditForm) return;
      let formId = vm.formId;
      let id = 0;
      if (vm.isEditForm) {
        formId = vm.formDoc.formid;
        id = vm.formId;
      }

      try {
        vm.isLoading = true;
        let json = this.$store.getters.formJson;
        let form = JSON.parse(JSON.stringify(vm.form));
        let formFiles = this.$store.getters.formFiles;
        for (let key of Object.keys(formFiles)) {
          if (vm.form[key] instanceof File) {
            form[key] = await vm.uploadFile(vm.form[key]);
            _.set(json, `${formFiles[key]}}`, form[key]);
          } else {
            for (let index of Object.keys(form[key])) {
              if (vm.form[key][index].status == "finished") {
                form[key][index] = vm.form[key][index].name;
                _.set(json, `${formFiles[key]}.${[index]}`, form[key][index]);
              } else {
                form[key][index] = await vm.uploadFile(
                  vm.form[key][index].file
                );
                _.set(json, `${formFiles[key]}.${[index]}`, form[key][index]);
              }
            }
          }
        }
        let doc = new FormSubmitData({
          Dml_Indicator: vm.isEditForm ? "UP" : "IS",
          Formid: formId,
          Id: id,
          responsedataweb: JSON.stringify(json),
          Versionid: vm.version.Version_Id,
          Responsedata: JSON.stringify(form),
        });
        let { status, msg, data } = (
          await formBuilderService.submitForm({ ...doc, ...vm.query })
        ).data;
        vm.isLoading = false;
        if (!status) {
          window.parent.postMessage("Error", "*");
          vm.$alert.show(msg);
        } else {
          data = data.Table ? data.Table[0] : null;
          window.parent.postMessage("Suceess", "*");
          window.parent.postMessage(data, "*");
          //  location.reload();
        }
      } catch (error) {
        vm.handleError(error);
      }
    },
    uploadFile(file) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          let form = new FormData();
          form.append("file", file);
          let id = this.$route.params.id;
          let params = { FormId: id, version: new Date().getTime() };
          let res = await formBuilderService.uploadFormFile({
            params,
            data: form,
          });
          console.log(res);
          resolve(res.data.data);
        } catch (error) {
          reject(error);
        }
      });
    },
    formpreview() {
      this.$refs.form.validate((errors) => {
        if (!errors) {
          this.submitPreview();
        }
      });
    },
    async submitPreview() {
      const vm = this;
      try {
        vm.isLoading = true;
        let json = this.$store.getters.formJson;
        window.parent.postMessage(JSON.stringify(json), "*");
        vm.isLoading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    initFormStep(add) {
      const vm = this;
      vm.currentStep = vm.currentStep + add;
      let index = vm.currentStep - 1;
      vm.$store.commit("setFormStep", vm.formSteps[index]);
    },
  },
  mounted() {
    const vm = this;
    window.addEventListener("message", function (event) {
      var receivedMessage = event.data;

      // Check if it is a trigger submit message
      if (receivedMessage === "trigger-submit") {
        vm.validate();
      }
    });
    this.$store.commit("resetForm");
    if (this.isForm || this.fetchForm) {
      this.fetchForm();
    } else {
      this.fetchPreviewForm();
    }
  },
};
</script>

<style lang="scss">
.form-preview {
  .form-item {
    cursor: unset !important;
  }
}
.print-end {
  display: flex;
  justify-content: end;
  margin-right: 15px;
}
.form-header,
.form-footer,
.header-space,
.footer-space {
  //  display: none;
}
@media print {
  .header-space,
  .footer-space {
    display: table;
  }
  .form-header {
    position: fixed;
    display: block;
    top: 20px;
    left: 20px;
    right: 20px;
    z-index: 100;
  }
  .form-footer {
    display: block;
    position: fixed;
    bottom: 0;
    left: 20px;
    right: 20px;
    z-index: 100;
  }
  body {
    -webkit-print-color-adjust: exact;
  }
  .n-button {
    display: none !important;
  }
  .ql-toolbar {
    display: none !important;
  }
  .ql-container {
    border-top: 1px solid #ccc !important;
  }
}
</style>
