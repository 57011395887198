<template>
  <div class="d-flex">
    <radio-group-field
      :label="label"
      :field="field"
      :help="settings.help_text"
      :options="options"
    ></radio-group-field>
    <fb-field-config :field="field"></fb-field-config>
  </div>
</template>

<script>
import { getSettings } from "../../assets/js/common.helper";
import RadioGroupField from "../ui/form/RadioGroupField.vue";
import FbFieldConfig from "./FbFieldConfig.vue";
export default {
  props: { field: {} },
  components: { FbFieldConfig, RadioGroupField },
  computed: {
    label() {
      return this.field.Form_Label;
    },
    settings() {
      return getSettings({ ...this.field });
    },
    options() {
      let options = this.settings.options || [];
      return options.map((label) => ({ label, value: label }));
    },
  },
};
</script>

<style>
</style>