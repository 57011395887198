<template>
  <div v-if="html" v-html="html"></div>
</template>

<script>
export default {
  props: {
    html: {},
  },
};
</script>

<style>
.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
</style>
