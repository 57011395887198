import { createStore } from "vuex";

export default createStore({
  state: {
    _form: {},
    _formJson: {},
    _formRules: {},
    _formData: {},
    _formStep: {},
    _formSteps: [],
    _formFields: [],
    _formConditions: [],
    _formDynamicOptions: {},
    _formFiles: {},
  },
  getters: {
    formJson: (state) => state._formJson,
    form: (state) => state._form,
    formRules: (state) => state._formRules,
    formData: (state) => state._formData,
    formSteps: (state) => state._formSteps,
    formConditions: (state) => state._formConditions,
    formStep: (state) => state._formStep,
    formFields: (state) => state._formFields,
    formDynamicOptions: (state) => state._formDynamicOptions,
    formFiles: (state) => state._formFiles,
  },
  mutations: {
    updateFormDynamicOptions(state, { key, value }) {
      let json = state._formDynamicOptions;
      json[key] = value;
      state._formDynamicOptions = json;
    },
    setFormJson(state, val) {
      state._formJson = val;
    },
    setForm(state, val) {
      state._form = val;
    },
    setFormRules(state, val) {
      state._formRules = val;
    },
    resetForm(state) {
      state._form = {};
      state._formJson = {};
      state._formData = {};
      state._formStep = {};
      state._formSteps = [];
      state._formFields = [];
      state._formConditions = [];
    },
    setFormData(state, payload) {
      state._formData = payload;
    },
    setFormSteps(state, payload) {
      state._formSteps = payload.sort(
        (a, b) => a.Step_Position - b.Step_Position
      );
    },
    setFormConditions(state, payload) {
      state._formConditions = payload;
    },
    setFormFields(state, payload) {
      state._formFields = payload.sort(
        (a, b) => a.Field_Position - b.Field_Position
      );
    },
    setFormStep(state, payload) {
      state._formStep = payload;
    },
    setFormFiles(state, payload) {
      state._formFiles = payload;
    },
  },
  actions: {},
  modules: {},
});
