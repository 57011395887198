<template>
  <div class="mb-3 input-feedback">
    <div class="d-flex align-items-center mb-1">
      <span :style="labelStyle">
        {{ label }}
      </span>
      <help-text class="ml-1" v-if="help"> {{ help }}</help-text>
      <div class="flex-1"></div>
      <n-button @click="clear()" quaternary type="info" size="tiny">
        Clear
      </n-button>
    </div>
    <n-card size="small">
      <VueSignaturePad
        :options="{ onEnd }"
        v-model="input"
        height="250px"
        ref="signaturePad"
      />
    </n-card>
    <input-field
      :value="input"
      :rules="rules"
      :path="path"
      :settings="settings"
    ></input-field>
  </div>
</template>

<script>
import HelpText from "../HelpText.vue";
import InputField from "./InputField.vue";
export default {
  components: { HelpText, InputField },
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    path: {},
    help: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      input: "",
    };
  },
  methods: {
    async onEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.input = "";
      } else {
        this.input = data;
      }
      this.$emit("input", this.input);
      console.log(this.input);
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
      this.input = "";
    },
    initSignature() {
      console.log(this.value);
      this.$refs.signaturePad.fromDataURL(this.value);
    },
  },
  mounted() {
    this.initSignature();
  },
};
</script>

<style>
</style>