<template>
  <div class="d-flex">
    <input-field
      :label="label"
      :path="field.Field_Id"
      :help="settings.help_text"
      :type="type"
      :field="field"
      :value="settings.default_value"
      :placeholder="settings.placeholder"
    />
    <fb-field-config :field="field"></fb-field-config>
  </div>
</template>

<script>
import { getSettings } from "../../assets/js/common.helper";
import InputField from "../ui/form/InputField.vue";
import FbFieldConfig from "./FbFieldConfig.vue";
export default {
  props: { field: {} },
  components: { InputField, FbFieldConfig },
  computed: {
    label() {
      return this.field.Form_Label;
    },
    settings() {
      return getSettings({ ...this.field });
    },
    type() {
      return this.field.Field_Type;
    },
  },
};
</script>

<style>
</style>