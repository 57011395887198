import { createRouter, createWebHistory } from "vue-router";
import FormBuilder from "../pages/FormBuilder.vue";
import FormPreview from "../pages/FormPreview.vue";
import SuccessPage from "../pages/SuccessPage.vue";

const routes = [
  {
    path: "/form-builder/:id",
    name: "form-builder",
    component: FormBuilder,
  },
  {
    path: "/form-preview/:id",
    name: "form-preview",
    component: FormPreview,
  },
  {
    path: "/form/:id",
    name: "form",
    component: FormPreview,
  },
  {
    path: "/edit-form/:id",
    name: "edit-form",
    component: FormPreview,
  },
  {
    path: "/success-page",
    name: "success-page",
    component: SuccessPage,
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

export default router;
