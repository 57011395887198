import rulesConstant from "../assets/constants/rules.constant";
import { ImagePrefix, getSettings } from "../assets/js/common.helper";
import axios from "axios";
import {
  FormCondition,
  FormField,
  FormMaster,
  FormStep,
} from "../assets/model/form.master.model";
import formBuilderService from "../assets/services/form.builder.service";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  computed: {
    formStepFields() {
      return [...this.formFields].filter((i) => {
        if (this.isStepForm)
          return i.Step_id == new FormStep(this.formStep).Step_id;
        return true;
      });
    },
    getParentPath() {
      let label = this.field.Form_Label || "";
      let path = this.parentPath;
      if (label && path) {
        path = `${path}.["${label}"]`;
      } else if (label) {
        path = `["${label}"]`;
      }
      console.log(path);
      return path;
    },
    ...mapGetters(["formDynamicOptions", "formRules", "form"]),
  },
  methods: {
    async onPositionChange() {
      let list = this.fieldList.map((item, i) => ({
        Dml_Indicator: "UPP",
        Field_Position: i + 1,
        Field_Id: item.Field_Id,
      }));
      const vm = this;
      try {
        vm.$loader.show();
        let { status, msg } = (await formBuilderService.updatePosition(list))
          .data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert(msg);
          return;
        }
        vm.$loader.hide();
      } catch (error) {
        vm.handleError(error);
      }
    },
    fetchFormData(formId) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const vm = this;
        try {
          let doc = new FormMaster({ Formid: formId });
          let { status, data, msg } = (await formBuilderService.formMaster(doc))
            .data;
          if (status != true) {
            reject({ message: msg });
          } else if (data.Table == undefined || data.Table.length == 0) {
            reject({ message: '"Form not found!"' });
          } else {
            vm.$store.commit("setFormData", data.Table[0]);
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    fetchFormSteps() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const vm = this;
        try {
          let formId = new FormMaster(vm.formData).Formid;
          let doc = new FormStep({ Form_id: formId });
          let { status, data, msg } = (await formBuilderService.formStep(doc))
            .data;
          if (status != true) {
            reject({ message: msg });
          } else {
            vm.$store.commit("setFormSteps", data.Table || []);
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    fetchFormConditions() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const vm = this;
        try {
          let formId = new FormMaster(vm.formData).Formid;
          let doc = new FormCondition({ Form_id: formId });
          let { status, data, msg } = (
            await formBuilderService.formCondition(doc)
          ).data;
          if (status != true) {
            reject({ message: msg });
          } else {
            console.log("conditions", data.Table);
            vm.$store.commit("setFormConditions", data.Table || []);
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    fetchFormFields() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const vm = this;
        try {
          let formId = new FormMaster(vm.formData).Formid;
          let doc = new FormField({ Form_id: formId });
          let { status, data, msg } = (await formBuilderService.formField(doc))
            .data;
          if (status != true) {
            reject({ message: msg });
          } else {
            vm.$store.commit("setFormFields", data.Table || []);
            // vm.initFormRules();
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    initFormValues() {
      const vm = this;
      let form = { ...vm.form };
      vm.formFields.forEach((item) => {
        let field = new FormField(item);
        let { default_value } = getSettings({ ...item });
        let val = form[field.Field_Id];
        if (val == undefined && default_value != undefined)
          form[field.Field_Id] = default_value;
        if (
          item.Field_Type == "file-upload" &&
          this.$route.name == "edit-form"
        ) {
          form[field.Field_Id] = val.map((i) => {
            return { name: i, url: `${ImagePrefix}${i}`, status: "finished" };
          });
        }
      });
      vm.$store.commit("setForm", form);
    },
    initFormRules() {
      let rules = {};
      this.formFields.forEach((item) => {
        let settings = getSettings({ ...item });
        let type = item.Field_Type;
        let { is_required } = settings;
        let id = item.Field_Id;
        if (is_required == true) {
          rules[id] = rulesConstant.required;
          if (type == "email") rules[id] = rulesConstant.email;
        } else if (type == "email") {
          rules[id] = rulesConstant.emailOptional;
        }
      });
      this.$store.commit("setFormRules", rules);
    },
    formatString(expression, ob) {
      const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
      let text = expression.replace(templateMatcher, (substring, value) => {
        value = _.get(ob, value);
        return value;
      });
      return text;
    },

    async fetchDynamicOptions({ isInput, previousForm, field }) {
      try {
        const vm = this;
        let settings = getSettings({ ...field });
        if (settings.is_dynamic != true) {
          return;
        }
        let { url, method, body, list_key, name_key, value_key } = settings.api;
        let formFields = body.filter((i) => i.is_field == true);
        let nonFormFields = body.filter((i) => i.is_field != true);
        let formFieldsValue = [];
        formFields.forEach((i) => {
          let value = this.form[i.value];
          formFieldsValue.push({
            name: i.name,
            value,
          });
        });
        let params = {};
        [...formFieldsValue, ...nonFormFields].forEach((i) => {
          params[i.name] = i.value;
        });
        if (isInput) {
          let previousParams = {};
          formFields.forEach((i) => {
            let value = previousForm[i.value];
            previousParams[i.name] = value;
          });
          nonFormFields.forEach((i) => {
            previousParams[i.name] = i.value;
          });
          console.log("Previous params", previousParams);
          console.log("New params", params);
          if (JSON.stringify(previousParams) == JSON.stringify(params)) {
            console.log("Previous params is same...");
            return;
          }
        }
        vm.$store.commit("updateFormDynamicOptions", {
          key: field.Field_Id,
          value: [],
        });
        let options = { url, method };
        if (method == "get" || method == "GET") {
          options.params = params;
          options.method = "GET";
        }
        if (method == "post") {
          options.data = params;
          options.method = "POST";
        }
        console.log(method, options);
        let data = (await axios(options)).data;
        let list = list_key ? _.get(data, list_key, []) : data || [];
        let items = [];
        list.forEach((item) => {
          let name = _.get(item, name_key);
          let value = _.get(item, value_key);
          if (typeof item == "string") {
            name = item;
            value = item;
          }
          if (
            name_key &&
            name_key.indexOf("{{") != -1 &&
            name_key.indexOf("}}") != -1
          ) {
            name = vm.formatString(name_key, item);
          }
          if (name && value) items.push({ label: name, value });
        });
        vm.$store.commit("updateFormDynamicOptions", {
          key: field.Field_Id,
          value: items,
        });
      } catch (error) {
        console.error(
          `Error while fetching the dynamic items ${this.field.Form_Label}`,
          error
        );
      }
    },
  },
};
