<template>
  <div class="dashed-border p-3 mb-3">
    <div class="d-flex">
      <div class="flex-1"></div>
      <fb-field-config :field="field"></fb-field-config>
    </div>
    <img :src="src" :style="imageStyle" />
  </div>
</template>

<script>
import { getSettings, ImagePrefix } from "../../assets/js/common.helper";
import FbFieldConfig from "./FbFieldConfig.vue";
import formMixins from "../../mixins/form.mixins";
export default {
  props: { field: {} },
  components: { FbFieldConfig },
  mixins: [formMixins],
  computed: {
    src() {
      const vm = this;
      let image = getSettings({ ...vm.field }).image;
      return ImagePrefix + image;
    },
    data() {
      let data = getSettings({ ...this.field }).table_rows || [];
      return data;
    },
  },
};
</script>

<style></style>
