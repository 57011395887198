<template>
  <div class="p-3 pt-2 dashed-border mb-3">
    <div class="d-flex items-center dashed-border--bottom mb-2 pb-2">
      <div class="flex-1" :style="labelStyle">
        {{ field.Form_Label }}
      </div>
      <fb-field-config :field="field"></fb-field-config>
    </div>
    <draggable
      v-model="fieldList"
      item-key="Field_Id"
      tag="div"
      @change="onPositionChange"
    >
      <template #item="{ element }">
        <div>
          <fb-preview :field="element" :key="element.Field_Id"></fb-preview>
        </div>
      </template>
    </draggable>
    <fb-add-field :parent="parent"></fb-add-field>
  </div>
</template>
  
  <script>
import formMixins from "../../mixins/form.mixins";
import FbFieldConfig from "./FbFieldConfig.vue";
import draggable from "vuedraggable";
import { FormField } from "../../assets/model/form.master.model";
import FbAddField from "./FbAddField.vue";
export default {
  name: "FormSection",
  props: {
    field: {},
  },
  mixins: [formMixins],
  components: {
    FbFieldConfig,
    draggable,
    FbAddField,
  },
  data() {
    return {
      fieldList: [],
      title: "",
    };
  },
  watch: {
    formStepFields() {
      this.initFields();
    },
  },
  computed: {
    parent() {
      return new FormField(this.field).Field_Id;
    },
  },
  methods: {
    initFields() {
      this.fieldList = [...this.formStepFields]
        .filter((i) => i.Parent == this.parent)
        .map((i) => ({ ...i }));
    },
    async changeTitle() {},
  },
  mounted() {
    this.initFields();
  },
};
</script>
  