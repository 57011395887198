import { FormTypes } from "../assets/constants/form.builder.constant";
import { mapGetters } from "vuex";
import { getSettings } from "../assets/js/common.helper";
export default {
  computed: {
    ...mapGetters([
      "formData",
      "formSteps",
      "formStep",
      "formFields",
      "formConditions",
    ]),
    isStepForm() {
      return this.formData.Form_Type == FormTypes.StepForm;
    },
    labelStyle() {
      let { label_font, label_color } = getSettings({
        ...this.field,
      });
      let style = {};
      if (label_font) style["font-size"] = `${label_font}px!important`;
      if (label_color) style["color"] = `${label_color}!important`;
      return style;
    },
    sectionStyle() {
      let { label_font, label_color, background_color, is_collapsible } =
        getSettings({
          ...this.field,
        });
      if (!background_color && this.field.Field_Type == "section") {
        background_color = "#18a058";
      }
      if (!label_color && this.field.Field_Type == "section") {
        label_color = "white";
      }
      let style = {};
      if (is_collapsible == true) style["cursor"] = "pointer";
      if (label_font) style["font-size"] = `${label_font}px!important`;
      if (label_color) style["color"] = `${label_color}!important`;
      style["background-color"] = `${background_color}!important`;
      return style;
    },
    imageStyle() {
      let { width, height } = getSettings({
        ...this.field,
      });
      return {
        width: `${width}`,
        height: `${height}`,
        "object-fit": "contain",
      };
    },
  },
  methods: {
    handleError(error) {
      const vm = this;
      vm.loading = false;
      vm.$loader.hide();
      vm.$alert(error.message);
    },
  },
  mounted() {
    const nearestElement = (el) => {
      while (el?.nodeType !== 1 /* ELEMENT */) el = el.parentElement;
      return el;
    };
    const insertStyles = (styles) => {
      if (styles?.length) {
        this.__style = document.createElement("style");
        this.__style.innerText = styles.join().replace(/\n/g, "");
        nearestElement(this.$el).prepend(this.__style);
      }
    };

    insertStyles(this.$?.type.styles);
    if (this.$options.components) {
      for (const comp of Object.values(this.$options.components)) {
        insertStyles(comp.styles);
      }
    }
  },
  unmounted() {
    this.__style?.remove();
  },
};
