import axios from "axios";
import { ApiPrefix, ApiToken } from "../js/common.helper";
let baseURL = ApiPrefix;
let http = axios.default.create({
  baseURL: `${baseURL}`,
  headers: {
    Authorization: `Bearer ${ApiToken}`,
  },
});
class FormBuilderService {
  formMaster(data) {
    return http.post("/Form_Builder/insert_update_deleteFormMaster", data);
  }

  updatePosition(data) {
    return http.post("/Form_Builder/insert_FormField", data);
  }

  formStep(data) {
    return http.post("/Form_Builder/insert_update_deleteFormStep", data);
  }
  formCondition(data) {
    return http.post("/Form_Builder/insert_update_deleteFormFormula", data);
  }
  formField(data) {
    return http.post("/Form_Builder/insert_update_deleteFormField", data);
  }
  formPublish(data) {
    return http.post("/Form_Builder/insert_update_deleteFormversion", data);
  }
  submitForm(data) {
    return http.post("/Form_Builder/insert_update_deleteFormmasterdata", data);
  }
  getEditForm(params) {
    return http.get("/Form_Builder/FormMasterData", { params });
  }
  uploadFile({ params, data }) {
    return http.post("/ByteArrayAPI/formbuilderImage", data, { params });
  }
  uploadFormFile({ params, data }) {
    return http.post("/ByteArrayAPI/formbuilderImageSave", data, { params });
  }
}

export default new FormBuilderService();
