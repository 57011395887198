<template>
  <div>
    <div class="d-flex align-center mb-1">
      <div class="subtitle-1 black--text">Configure Formula</div>
      <v-spacer></v-spacer>
    </div>
    <editor-field
      v-model="formula"
      @input="onInput"
      outlined
      class="mb-1"
      height="150px"
      :items="fields"
      ref="textarea"
      no-resize
    >
    </editor-field>
    <div v-if="errorMessage" class="text-red">
      {{ errorMessage }}
    </div>
    <div class="note" v-html="note"></div>
  </div>
</template>
  
  <script>
import { Parser } from "hot-formula-parser";
import EditorField from "../ui/form/EditorField.vue";
import $ from "jquery";
import formMixins from "../../mixins/form.mixins";
export default {
  components: { EditorField },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialog: false,
      isValid: false,
      field: "",
      formula: "",
    };
  },
  watch: {
    formula() {
      this.onInput();
    },
  },
  mixins: [formMixins],
  computed: {
    errorMessage() {
      const vm = this;
      let { isParsable, formula } = vm;
      if (!formula) return "Formula is required";
      if (!isParsable) return "Formula is invalid.";
      return "";
    },
    isParsable() {
      var parser = new Parser();
      const vm = this;
      let formula = vm.formatText;
      formula = vm.formatFormula(formula);
      let val = parser.parse(formula);
      console.log("error", val.error);
      return val.error == null;
    },
    formatText() {
      return this.formula;
    },
    note() {
      return `Note: Formula can be created using the Fields, numbers and these operators:  + - / * ( )
        <br/>Example: ( ( field1 + field2 - field3 ) * 0.5 ) / 2`;
    },
    fields() {
      let list = [...this.formFields].filter(
        (i) => i.Field_Type == "questioner"
      );
      return list.map((i) => ({ name: i.Form_Label, id: i.Field_Id }));
    },
  },
  methods: {
    onInput() {
      this.$emit("update:modelValue", this.errorMessage ? "" : this.formula);
    },
    formatFormula(message) {
      let doc = document.createElement("div");
      doc.innerHTML = message;
      $(doc)
        .find("label")
        .each((i, item) => {
          $(item).html(0);
        });
      message = doc.innerText;
      console.log("formula", message);
      message = message.split("\n").join("<br/>");
      message = message.split("x").join("*");
      return message;
    },
    init(value) {
      const vm = this;
      vm.formula = value;
      vm.$refs.textarea.initFormula(value);
    },
  },
};
</script>
  
  <style></style>
  