<template>
  <div class="d-flex mb-2">
    Configure Column
    <div class="flex-1"></div>
    <fb-add-table-column @add="addColumn" />
  </div>
  <n-data-table
    class="mb-2"
    :columns="columns"
    :data="data"
    :pagination="false"
    :bordered="false"
  />
</template>

<script>
import { h } from "vue";
import DeleteIconButton from "../ui/buttons/DeleteIconButton.vue";
import FbAddTableColumn from "./FbAddTableColumn.vue";

export default {
  components: { FbAddTableColumn },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: [],
    };
  },
  computed: {
    columns() {
      let { remove } = this;
      const vm = this;
      let list = [
        {
          key: "label",
          title: "Label",
        },
        {
          key: "type",
          title: "Type",
        },
        {
          key: "actions",
          width: "100px",
          title: "Actions",
          render(row, index) {
            return h("div", { class: "d-flex" }, [
              h(FbAddTableColumn, {
                item: row,
                type: "icon",
                index: index,
                onUpdate: (event) => {
                  vm.data[index] = event;
                  vm.onInput();
                },
              }),
              h(DeleteIconButton, {
                size: "small",
                onClick: () => remove(index),
              }),
            ]);
          },
        },
      ];
      return list;
    },
  },
  methods: {
    remove(index) {
      this.$confirm({
        message: "Are you sure, Do you want to remove this column?",
        onConfirm: () => {
          this.data.splice(index, 1);
          this.onInput();
        },
      });
    },
    addColumn(item) {
      this.data.push(item);
      this.onInput();
      console.log(item);
    },
    onInput() {
      this.$emit("update:modelValue", this.data);
    },
  },
  mounted() {
    this.data = this.modelValue || [];
  },
};
</script>

<style></style>
